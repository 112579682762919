<template>
    <div>
        <Breadcrumbs
      :items="breadcrumbs"
      page_title="usercoursestatusreport"
      :item_no="item_No ? item_No : 0"
    />
    <v-container>
        <v-card>
            <v-row justify="space-between" class="ma-1 pa-2">
          <v-col cols="12" xs="12" sm="4" xl="3">
            <v-text-field
              outlined
              dense
              hide-details
              style="border-radius: 8px; border-color: #c0c0c0"
              clearable
              prepend-inner-icon="mdi-magnify"
              :placeholder="$t('search')"
              v-model="search"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            xs="12"
            sm="8"
            xl="9"
            style="text-align: right !important"
          >
            <v-btn
              class="text-capitalize"
              style="
                color: #424242;
                font-size: 16px;
                border: 1px solid #c0c0c0;
                box-shadow: unset !important;
                border-radius: 8px;
                background: #ffffff 0% 0% no-repeat padding-box;
              "
              @click="showFilterTools = !showFilterTools"
            >
              <v-icon class="mr-2" :small="true">mdi-tune-variant</v-icon>
              {{ $t("filter") }}
            </v-btn>

            <v-btn
              outlined
              @click="exportExcel"
              style="
                color: #a6cc39;
                font-size: 16px;
                border: 1px solid #c0c0c0;
                box-shadow: unset !important;
                border-radius: 8px;
                background: #ffffff 0% 0% no-repeat padding-box;
              "
              class="ml-3 text-capitalize"
            >
              <v-icon class="mr-2 mb-1">mdi-export-variant</v-icon>
              {{ $t("exportexcel") }}
            </v-btn>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <div v-if="showFilterTools">
        <v-row  class="ma-1">
          <v-col cols="12" sm="6" md="4" lg="3" xl="3">
            <div class="pre-fix">
            <label
                for="quizname"
                style="
                  font-size: 16px;
                  color: #47484b;
                  font-weight: bold;
                  margin-top: 5px;
                "
              >
                {{ $t("user")}}:
              </label>

              <v-select
              v-model="userLISTD"
              :items="useritems"
              solodense
              dense
              outlined
              hide-details
              item-text="fullName"
              item-value="userID"
              hide-no-data
              @change="(event) => SelectUser(event)"
              @blur="clearSearchWord(searchtype)"
              solo
              clearable
              multiple
              placeholder="-- Please select --"
            >
              <template v-slot:prepend-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-text-field
                      v-model="searchuser"
                      :placeholder="$t('search')"
                      @input="searchInLists(), (searchtype = 'user')"
                      autocomplete="off"
                      hide-details
                      class="searchinlist"
                      append-icon="mdi-magnify"
                    ></v-text-field>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item ripple @click="SelectAllUser">
                  <v-list-item-action>
                    <v-icon :color="userLISTD.length > 0 ? '#a6cc39' : ''">{{
                      Usericon
                    }}</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>Select All</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>

              <template v-slot:selection="{ item, index }">
                <v-chip v-if="index === 0">
                  <span>{{ item.fullName }}</span>
                </v-chip>
                <span v-if="index === 1" class="grey--text text-caption"
                  >(+{{ userLISTD.length - 1 }} others)</span
                >
              </template>
            </v-select>
            </div>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3" xl="3">
            <div class="pre-fix">
              <label
              for="group"
              style="
                font-size: 16px;
                color: #424242;
                font-weight: bold;
                margin-top: 5px;
              "
              >{{ $t("group") }}:</label
            >
              <v-select
              v-model="dept"
              :items="deptitems"
              hide-details
              item-text="groupName"
              item-value="groupID"
              hide-no-data
              solodense
              dense
              outlined
              @change="(event) => SelectGroup(event)"
              @blur="clearSearchWord(searchtype)"
              solo
              clearable
              multiple
              placeholder="-- Please select --"
            >
              <template v-slot:prepend-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-text-field
                      v-model="searchgroup"
                      :placeholder="$t('search')"
                      @input="searchInLists(), (searchtype = 'group')"
                      autocomplete="off"
                      hide-details
                      class="searchinlist"
                      append-icon="mdi-magnify"
                    ></v-text-field>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item ripple @click="getSelectAll">
                  <v-list-item-action>
                    <v-icon :color="dept.length > 0 ? '#a6cc39' : ''">{{
                      icon
                    }}</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>Select All</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>

              <template v-slot:selection="{ item, index }">
                <v-chip v-if="index === 0">
                  <span>{{ item.groupName }}</span>
                </v-chip>
                <span v-if="index === 1" class="grey--text text-caption"
                  >(+{{ dept.length - 1 }} others)</span
                >
              </template>
            </v-select>
            </div>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3" xl="3">
           
           <div class="pre-fix mt-7">
               <v-btn 
               text
           class="text-capitalize px-2"
           height="40"
           style="
             border: 1px solid #c0c0c0;
             border-radius: 8px;
             color: #424242;
             font-size: 16px;
           "
              @click="clearAll"
               >{{ $t("clearall") }}</v-btn>
               <v-btn 
               min-width="100"
           height="40"
           class="text-capitalize btn_hover_effect ml-3 px-2"
           style="
             color: #ffff;
             border: 1px solid #a6cc39;
             border-radius: 8px;
             font-size: 16px;
           "
           color="#A6CC39"
            @click="searchMain">{{ $t("search") }}</v-btn>
           </div>
           
          </v-col>
        </v-row>
        <v-row  class="ma-1">
          <!-- <v-col cols="12" sm="6" md="4" lg="3" xl="3">
            <div class="pre-fix">
              <label
                for="quizname"
                style="
                  font-size: 16px;
                  color: #47484b;
                  font-weight: bold;
                  margin-top: 5px;
                "
                >{{ $t("status") }} :</label
              >
              <v-select
                v-model="selectedOption"
                :items="status"
                label="Select an option"
                outlined
                dense
                ></v-select>
            </div>
          </v-col> -->
          
        </v-row>
        </div>
        <v-row no-gutters class="px-5">
          <v-col>
            <v-data-table
              :loading="loading"
              loading-text="Loading... Please wait"
              :headers="headers"
              :items="tableData"
              :search="search"
              hide-default-footer
              @page-count="pageCount = $event"
              :page.sync="page"
              :items-per-page="itemsPerPage"
              class="pt-6 report-table"
              :single-expand="true"
              item-key="userID"
            >
           <!-- Slot for Completion Percentage -->
            <template v-slot:item.completionPercentage="{ item }">
              {{ item.completionPercentage }} %
            </template>
            <template v-slot:item.actions="{ item }">
              <v-tooltip  v-model="item.tooltip">
                    <template v-slot:activator="{ props }">
                      <v-icon
                        v-bind="props"
                        class="detail-icon"
                        style="color: #ECC407; cursor: pointer"
                         @click="Details(item)"
                        @mouseenter="item.tooltip = true"
                        @mouseleave="item.tooltip = false"
                      >
                        mdi-alert-circle
                      </v-icon>
                    </template>
                   
                  </v-tooltip>
                 
              </template>
            </v-data-table>
          </v-col>
        </v-row>
        <v-row class="ma-1">
          <v-col cols="12" xs="12" sm="9" md="10">
            <div class="d-flex text-left">
              <v-pagination
                circle
                v-model="page"
                class="pagination"
                :length="pageCount"
              ></v-pagination>
            </div>
          </v-col>
          <v-col cols="12" xs="12" sm="3" md="2" class="d-flex justify-end">
            <p class="mt-3 pr-4">{{ $t("Show") }}</p>
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  width="70"
                  v-bind="attrs"
                  v-on="on"
                  style="border: 1px solid #c0c0c0; opacity: 1"
                  class="text-capitalize mt-1"
                  outlined
                >
                  {{ itemsPerPage }}
                  <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-for="(item, index) in paginationCount"
                  :key="index"
                  @click="itemsPerPage = item.title"
                >
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
        </v-row>
        </v-card>
        
    </v-container>
    <v-dialog
        v-model="detaildialog"
       
        width="1000"
        height="800"
        persistent
        :scrollable="true"
      >
        <v-card>
          <v-card-title style="font-size: 20px">
            <p class="dialog-p">
              <v-icon class="mb-1 mr-1">mdi-information-outline</v-icon
              >{{ $t("detail") }}
            </p>
            <v-spacer></v-spacer>
            <v-btn
              outlined
              @click="exportDetailExcel"
              style="
                color: #a6cc39;
                font-size: 16px;
                border: 1px solid #c0c0c0;
                box-shadow: unset !important;
                border-radius: 8px;
                background: #ffffff 0% 0% no-repeat padding-box;
              "
              class="ml-3 text-capitalize"
            >
              <v-icon class="mr-2 mb-1">mdi-export-variant</v-icon>
              {{ $t("exportexcel") }}
            </v-btn>
            <v-btn
              icon
              @click="detaildialog = false"
              class="ml-3"
              color="#424242"
              style="
                border-radius: 8px !important;
                border: 1px solid rgba(66, 66, 66, 0.05);
              "
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="mt-5">
            <v-row class="ml-3">
              <v-col cols="3" class="pl-0">
                <label
                  for="title"
                  style="
                    font-size: 16px;
                    color: #47484b;
                    font-weight: bold;
                    margin-top: 10px;
                  "
                  >{{ $t("fullname") }}:</label
                >
                <label
                  for="name"
                  style="
                    font-size: 16px;
                    color: #47484b;
                    margin-top: 0px;
                    margin-left: 5px;
                  "
                  >{{ formattedFullName }}</label
                >
              </v-col>
              <v-col cols="2" class="pl-0">
                <label
                  for="surname"
                  style="
                    font-size: 16px;
                    color: #47484b;
                    font-weight: bold;
                    margin-top: 5px;
                  "
                  >{{ $t("group") }}:</label
                >
                <label
                  for="name"
                  style="
                    font-size: 16px;
                    color: #47484b;
                    margin-top: 0px;
                    margin-left: 5px;
                  "
                  >{{ formattedGroupName }}</label
                >
              </v-col>
              <v-col cols="3" class="pl-0">
                <label
                  for="title"
                  style="
                    font-size: 16px;
                    color: #47484b;
                    font-weight: bold;
                    margin-top: 10px;
                    margin-left: 5px;
                  "
                  >{{ $t("totalcourses") }}:</label
                >
                <label
                  for="title"
                  style="
                    font-size: 16px;
                    color: #47484b;
                    margin-top: 10px;
                    margin-left: 0.5rem;
                  "
                  >{{ itemSelectedDetail.totalCourses }}</label
                >
              </v-col>
              <v-col cols="4" class="pl-0">
                <label
                  for="surname"
                  style="
                    font-size: 16px;
                    color: #47484b;
                    font-weight: bold;
                    margin-top: 5px;
                  
                  "
                  >{{ $t("completionpercentage") }}:</label
                >
                <label
                  for="name"
                  style="
                    font-size: 16px;
                    color: #47484b;
                    margin-top: 5px;
                    margin-left: 5px;
                  "
                  >{{ itemSelectedDetail.completionPercentage }} % </label
                >
              </v-col>
            </v-row>
            <v-row class="mt-1 ml-3">
              <v-col cols="3" class="pl-0">
                <label
                  for="title"
                  style="
                    font-size: 16px;
                    color: #47484b;
                    font-weight: bold;
                    margin-top: 10px;
                  "
                  >{{ $t("complete") }}:</label
                >
                <label
                  for="complete"
                  style="
                    font-size: 16px;
                    color: #47484b;
                    margin-top: 10px;
                    margin-left: 5px;
                  "
                  >{{ itemSelectedDetail.complete }}</label
                >
              </v-col>
              <v-col cols="2" class="pl-0">
                <label
                  for="surname"
                  style="
                    font-size: 16px;
                    color: #47484b;
                    font-weight: bold;
                    margin-top: 5px;
                  "
                  >{{ $t("notstarted") }}:</label
                >
                <label
                  for="notStarted"
                  style="
                    font-size: 16px;
                    color: #47484b;
                    margin-top: 0px;
                    margin-left: 5px;
                  "
                  >{{ itemSelectedDetail.notStarted }}</label
                >
              </v-col>
              <v-col cols="3" class="pl-2">
                <label
                  for="title"
                  style="
                    font-size: 16px;
                    color: #47484b;
                    font-weight: bold;
                    margin-top: 10px;
                  "
                  >{{ $t("lblinprogress") }}:</label
                >
                <label
                  for="inProgress"
                  style="
                    font-size: 16px;
                    color: #47484b;
                    margin-top: 10px;
                    margin-left: 5px;
                  "
                  >{{ itemSelectedDetail.inProgress }}</label
                >
              </v-col>
              <v-col cols="4" class="pl-0">
                <label
                  for="surname"
                  style="
                    font-size: 16px;
                    color: #47484b;
                    font-weight: bold;
                    margin-top: 5px;
                  "
                  >{{ $t("lbldelay") }}:</label
                >
                <label
                  for="delay"
                  style="
                    font-size: 16px;
                    color: #47484b;
                    margin-top: 5px;
                    margin-left: 5px;
                  "
                  >{{ itemSelectedDetail.delay }}</label
                >
              </v-col>
            </v-row>
            
          <v-row>
    <v-col cols="12">
      <v-tabs v-model="activeTab" grow color="secondary" direction="vertical">
  <v-tab value="tab-1">{{ $t("complete") }}</v-tab>
  <v-tab value="tab-2">{{ $t("notstarted") }}</v-tab>
  <v-tab value="tab-3">{{ $t("inprogress") }}</v-tab>
  <v-tab value="tab-4">{{ $t("delay") }}</v-tab>
</v-tabs>
    </v-col>
  </v-row>
          <v-divider
            :class="$vuetify.breakpoint.smAndDown ? 'mt-2' : 'mt-0'"
          ></v-divider>
          <v-row class="mt-3">
            <v-col cols="12" xs="12" sm="4" xl="3">
            <v-text-field
              outlined
              dense
              hide-details
              style="border-radius: 8px; border-color: #c0c0c0"
              clearable
              prepend-inner-icon="mdi-magnify"
              :placeholder="$t('search')"
              v-model="searchDetail"
            ></v-text-field>
          </v-col>
          </v-row>
          <v-row class="ml-1">
            <!-- Start Date Picker -->
            <v-col cols="2" class="pl-0">
              <label
                for="createdateM"
                style="
                  font-size: 16px;
                  color: #424242;
                  font-weight: bold;
                  margin-top: 5px;
                "
                >{{ $t("createdateM") }}</label
              >
              <v-menu
                v-model="frommenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="startdate"
                    prepend-inner-icon="mdi-calendar-blank-outline"
                    outlined
                    dense
                    readonly
                    v-bind="attrs"
                    placeholder="yyyy/mm/dd"
                    hide-details
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="startdate"
                 @change="StartViewDate()"
                ></v-date-picker>
              </v-menu>
             
            </v-col>

            <!-- End Date Picker -->
            <v-col cols="2" class="pl-0">
              <label
                for="to"
                style="
                  font-size: 16px;
                  color: #47484b;
                  font-weight: bold;
                  margin-top: 5px;
                "
                >{{ $t("to") }}
              </label>
              <v-menu
                v-model="tomenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="enddate"
                    prepend-inner-icon="mdi-calendar-blank-outline"
                    outlined
                    dense
                    placeholder="yyyy/mm/dd"
                    hide-details
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="enddate"
                  :allowed-dates="allowedEndDates"
                  @input="tomenu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
           
            <v-col cols="4" class="mt-9">
              <v-row justify="start" align="center">
                <v-btn 
                  text
                  class="text-capitalize px-2"
                  height="40"
                  style="
                    border: 1px solid #c0c0c0;
                    border-radius: 8px;
                    color: #424242;
                    font-size: 16px;
                  "
                  @click="clearAllCourse"
                >
                {{ $t("clearall") }}
                </v-btn>

                <v-btn 
                  min-width="100"
                  height="40"
                  class="text-capitalize btn_hover_effect ml-3 px-2"
                  style="
                    color: #ffff;
                    border: 1px solid #a6cc39;
                    border-radius: 8px;
                    font-size: 16px;
                  "
                  color="#A6CC39"
                  @click="searchDetails(itemSelectedDetail)"
                >
                {{ $t("search") }}
                </v-btn>
              </v-row>
            </v-col>
           
          </v-row>
          <v-data-table
            
            :loading="loading"
            loading-text="Loading... Please wait"
            :headers="detailsheader"
            :items="DetailsL"
            :search="searchDetail"
             item-key="index"
            @page-count="detailPageCount = $event"
              class="pt-6 report-table"
            :page.sync="detailpage"
            :items-per-page="itemsPerPage"
            :single-expand="true"
            hide-default-footer
            show-expand
          >
          
          
          <template v-slot:expanded-item="{ headers, item }">
              <!-- Add 2 empty columns for shifting -->
              <td :colspan="4"></td>  
              <td :colspan="headers.length - 4" style="padding-right: 0">
                <table class="table">
                  <caption></caption>
                  <tr>
                    <th></th>
                  </tr>
                  <tr v-for="(subItem, i) in item.contents" :key="i">
                    <!-- Shift columns by moving data two places forward -->
                    
                    <td
                        :colspan="headers.Type"
                        class="content-filename text-left"
                      >
                        {{ item.type == 0 ? null : subItem.type }}
                      </td>
                    <td 
                    :colspan="headers.Content"
                    class="content-content text-left">
                      {{ item.content == 0 ? null : subItem.content }}
                    </td>
                    <td 
                    :colspan="headers.FristView"
                    class="content-firstView text-left">
                      {{ item.firstView == 0 ? null :formatDate(subItem.firstView )}}
                    </td>
                    <td 
                    :colspan="headers.LastView"
                    class="content-lastView text-left">
                      {{ item.lastView == 0 ? null : formatDate(subItem.lastView )}}
                    </td>
                    <td 
                    :colspan="headers.Status"
                    class="content-status text-left">
                      {{ item.status == 0 ? null : subItem.status }}
                    </td>
                  </tr>
                </table>
              </td>
            </template>

              
            </v-data-table>

            <v-row class="ma-1">
          <v-col cols="12" xs="12" sm="9" md="10">
            <div class="d-flex text-left">
              <v-pagination
                circle
                v-model="detailpage"
                class="pagination"
                :length="detailPageCount"
              ></v-pagination>
            </div>
          </v-col>
          <v-col cols="12" xs="12" sm="3" md="2" class="d-flex justify-end">
            <p class="mt-3 pr-4">{{ $t("Show") }}</p>
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  width="70"
                  v-bind="attrs"
                  v-on="on"
                  style="border: 1px solid #c0c0c0; opacity: 1"
                  class="text-capitalize mt-1"
                  outlined
                >
                  {{ itemsPerPage }}
                  <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-for="(item, index) in paginationCount"
                  :key="index"
                  @click="itemsPerPage = item.title"
                >
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
        </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
</template>
<script>
import axios from "axios";
import Exceljs  from "exceljs";
import { saveAs } from "file-saver";

import {  mapActions } from "vuex";

export default {
components:{

},
data(){
    return{
      
      item_No: 0,
      showFilterTools: false,
      url:
      "EmployeeEXT/GetEmployeeExtAll?Id=" + localStorage.getItem("companyID"),
   
      searchuser: null,
      searchuserDetail: null,
      searchgroup: null,
      frommenu: false,
      frommenudetail: false,
      tomenu: false,
      tomenudetail: false,
      startdate: "",
     // enddate: "" ,
      tmpendDate: (val) => parseInt(val.split("-")[2], 10) % 2 === 0,
      tmpendEDate: (val) => parseInt(val.split("-")[2], 10) % 2 === 0,
      viewstartdate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    viewenddate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
      useritems: [],
      dept:[],
      deptitems: [],
      userLISTD: [],
      
      searchtype: null,
      searchcategory: null,
      values: [],
      searchtitle: null,
      Titleitems: [],
      Categoryitems: [],
      DetailsList: [],
      DetailsL: [],
      detailpage: 1,
      detailpageCount: 0,
      search:"",
      searchDetail:"",
      loading: false,
      detailloading: false,
      detaildialog: false,
        page: 1,
        detailPage: 1,
        detailPageCount:0,
    pageCount: 0,
    itemsPerPage: 10,
    paginationCount: [{ title: 10 }, { title: 20 }, { title: 30 }],
        selectedOption: null, // Holds the selected value
        options: ["Option 1", "Option 2", "Option 3"],
        status:["Complete","In Progress","Not Started","Delay"],
        breadcrumbs: [
      {
        //image: "house.png"
        sidebar_tilte: "report",
      },
      {
        text: "usercoursestatusreport", //assignmentcourse
      },
    ],
    detail: [
   {
    createDateString: "20/02/2025",
    name: "Insurence",
    title: "Science",
   }
    ],
    detailQuiz: [
   {
    createDateString: "20/02/2025",
    name: "Insurence",
    title: "Science",
   }
    ],
    userCourceReport: [],
    itemSelectedDetail: [],
    selectedIndex: null,
    //Courseitems: [],
    //course: [],
   // courseCopy: [],
   // searchcourse: "",
   // titleCopy: [],
    };
    
    
},
watch: {
    activeTab(newVal, oldVal) {
      console.log("Tab changed from", oldVal, "to", newVal);
      this.clearAllCourse(); // ✅ Reset fields when switching tabs
    },
    // startdate() {
    //   this.startdate = this.startdate
    //     ? this.startdate
    //     : new Date().toISOString().substr(0, 10);
    // },
    enddate() {
      this.enddate = this.enddate ? this.enddate : "";
      this.exportEdate = this.enddate;
    },
  },
computed:{
  
  enddate: {
      get() {
        let temp = new Date().toISOString().substr(0, 10);
        return temp;
      },
      set() {},
    },
  iconCourse() {
      if (this.selectAllCourse) return "mdi-checkbox-marked";
      return "mdi-checkbox-blank-outline";
    },
  
  activeTab: {
      get() {
        return this.$store.state.usercoursestatusreport.activeTab;
      },
       set(value) {
      //  // this.$store.dispatch('usercoursestatusreport/changeTab', value);
         this.changeTab(value); // ✅ Call Vuex action instead of directly modifying the state
       },
    },
    formattedFullName() {
    if (!this.itemSelectedDetail || !this.itemSelectedDetail.fullName) {
      return "N/A"; // Handle empty cases
    }

    let text = this.itemSelectedDetail.fullName;
    
    return text.length > 10 ? text.substring(0, 10) + "..." : text;
  },
  formattedGroupName() {
    if (!this.itemSelectedDetail || !this.itemSelectedDetail.groupName) {
      return "N/A"; // Handle empty cases
    }

    let text = this.itemSelectedDetail.groupName;
    
    return text.length > 5 ? text.substring(0, 5) + "..." : text;
  },
  tableData(){
 return this.userCourceReport.map((item,index) => ({
  ...item,
  index: index + 1,
 }))
  },
  selectAll() {
      return this.dept.length === this.deptitems.length;
    },
    selectAllDetail() {
      return this.dept.length === this.deptitems.length;
    },
    selectAllUser() {
      return this.userLISTD.length === this.useritems.length;
    },
  
    Usericon() {
      if (this.selectAllUser) return "mdi-checkbox-marked";
      return "mdi-checkbox-blank-outline";
    },
    UsericonDetail() {
      if (this.selectAllUserDetail) return "mdi-checkbox-marked";
      return "mdi-checkbox-blank-outline";
    },
  selectAllTitle() {
      return this.values.length === this.Titleitems.length;
    },
    headers() {
      return [
      {
          text: "#",
          value: "index",
          align: "left",
          width: "80px",
        },
        
        {
          text: this.$t("fullnamesm"),
          value: "fullName",
          align: "left",
          width: "180px",
        },
        {
          text: this.$t("group"),
          value: "groupName",
          align: "left",
          width: "280px",
        },
       
        {
          text: this.$t("totalcourses"),
          value: "totalCourses",
          align: "left",
          width: "210px",
        },
        {
          text: this.$t("complete"),
          value: "complete",
          align: "left",
          width: "180px",
        },
        {
          text: this.$t("inprogress"),
          value: "inProgress",
          align: "left",
          width: "180px",
          class: "table_header",
        },
        {
          text: this.$t("notstarted"),
           value: "notStarted",
          align: "left",
          width: "210px",
        },
        {
          text: this.$t("delay"),
          value: "delay",
          align: "left",
          width: "150px",
        },
        {
          text: this.$t("completionpercentage"),
          value: "completionPercentage",
          align: "left",
          width: "280px",
        },
        {
          text: this.$t("detail"),
          value: "actions",
          align: "left",
          width: "145px",
        },
      ];
    },
    detailsheader() {
    
      return [
        {
          text: "#",
          value: "index",
          align: "left",
          width: "100px",
        },
        {
          text: this.$t("coursename"),
          value: "courseName",
          align: "left",
          width: "180px",
        },
        {
          text: this.$t("startdateend"),
          value: "startDateTime",
          align: "left",
          width: "280px",
        },
        {
          text: this.$t("type"),
          value: "type",
          align: "left",
          width: "130px",
        },
        {
          text: this.$t("content"),
          value: "items",
          align: "left",
          width: "180px",
        },
        {
          text: this.$t("firstview"),
          value: "firstView",
          align: "left",
          width: "180px",
        },
        {
          text: this.$t("lastview"),
          value: "lastView",
          align: "left",
          width: "180px",
        },
        {
          text: this.$t("status"),
          value: "status",
          align: "left",
          width: "180px",
        },
      ];
    },
    icon() {
      if (this.selectAll) return "mdi-checkbox-marked";
      return "mdi-checkbox-blank-outline";
    },
    icondetail() {
      if (this.selectAllDetail) return "mdi-checkbox-marked";
      return "mdi-checkbox-blank-outline";
    },
},
methods: {
  formatDate(dateString) {
  
      if (!dateString) return "";
      const date = new Date(dateString);
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is zero-based
      const year = date.getFullYear();
      //console.log(`Formatted Date: ${day}/${month}/${year}`);
      return `${day}/${month}/${year}`; // Format: DD/MM/YYYY
    },
   
  loadData() {
  console.log("Active Tab:", this.activeTab);
  //console.log("DetailsList:", this.DetailsList);

  if (!this.DetailsList) return [];

  let result = [];

  switch (this.activeTab) {
    case 0:
      result = this.DetailsList.completed ? [...this.DetailsList.completed] : [];
      break;
    case 1:
      result = this.DetailsList.notStarted ? [...this.DetailsList.notStarted] : [];
      break;
    case 2:
      result = this.DetailsList.inProgress ? [...this.DetailsList.inProgress] : [];
      break;
    case 3:
      result = this.DetailsList.delayed ? [...this.DetailsList.delayed] : [];
      break;
    default:
      result = [];
  }

  // ✅ Add an index for each row
  result = result.map((item, index) => ({
    ...item,
    index: index + 1, // Start index from 1
    courseName : item.courseName || "Unknown Course",
    courseId: item.courseId || null,
    startDateTime:`${this.formatDate(item.startDateTime)} - ${this.formatDate(item.endDateTime)}`,
    
  }));
 
  this.DetailsL = result;
  
},
  SelectCourse(value) {
      this.Titleitems = [];
      this.values = [];
      if (value.length > 0) {
        for (let i of value) {
          const found = this.tempTitle.filter((c) => c.courseID == i);
          if (found.length != 0) {
            if (this.Titleitems.length == 0) {
              this.Titleitems = found;
            } else {
              for (let title of found) {
                if (title.title != "") {
                  this.Titleitems.push(title);
                }
              }
            }
          }
        }
      } else {
        this.Titleitems = this.tempTitle;
        this.ACReportList = [];
      }
    },
  clearAllCourse() {
     
      this.enddate = null;
      this.startdate = null;
     //this.enddate_New = null;
      //this.startdate_New = null;
      this.loadData();
     
     
      // this.enddate = "";
      // this.startdate = "";
      // this.clear = true;
      // this.GetActivityReport();
    },
    clearSearchWordCourse() {
      this.searchcourse = null;
      this.searchInLists();
    }, 
  changeTab(tab) {
    this.$store.dispatch('usercoursestatusreport/changeTab', tab);
  },
  resetTab() {
    this.$store.dispatch('usercoursestatusreport/resetTab');
  },
  
    ...mapActions("usercoursestatusreport", ["changeTab", "resetTab"]),
 
  
  async exportUser() {
      this.exportDialog = false;
      let url = null;
      let that = this;
      let temp = {
        d1: that.exportSdate ? new Date(that.exportSdate) : null,
        d2: that.exportEdate ? new Date(that.exportEdate) : null,
        companyid: localStorage.getItem("companyID"),
        offset: that.newdateoffset,
      };
      if (temp.d1) temp.d1.setUTCHours(0, 0, 0, 0);
      if (temp.d2) temp.d2.setUTCHours(23, 59, 59, 999);
      let sDate = that.exportSdate
        ? that.convertDate1(that.exportSdate) + " " + "00:00"
        : null;
      let eDate = that.exportEdate
        ? that.convertDate1(that.exportEdate) + " " + "23:59"
        : null;
      url = `${
        that.web_url
      }EmployeeEXT/ExportEmployeeReport?companyid=${localStorage.getItem(
        "companyID"
      )}&d1=${sDate}&d2=${eDate}&offset=${that.newdateoffset}`;
      axios
        .post(url)
        .then(function (response) {
          if (response.data.status == 0) {
            let URl =
              "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," +
              response.data.data.base64;
            let downloadLink = document.createElement("a");
            let filename = response.data.data.nameFile;

            downloadLink.href = URl;
            downloadLink.download = filename;
            downloadLink.click();
          }
          that.exportDialog = false;
          that.cancelClear();
        })
        .catch(function (err) {
          throw err;
        });
    },
  clearAll() {
    this.selectedOption = null;
      this.userLISTD = [];
      this.dept = [];
      this.enddate = "";
      this.startdate = "";
      this.clear = true;
      this.GetActivityReport();
    },
  GetUserItems() {
      let that = this;
      axios
        .get(
          `${that.web_url}EmployeeEXT/GetEmployeeExtAll?Id=` +
            localStorage.getItem("companyID")
        )
        //.post(`${that.web_url}User/GetUserDetail`)
        .then(function (response) {
          if (response.data.status == 0) {
            let temp = response.data.data.map((v) => ({
              ...v,
              select: false,
            }));
            that.useritems = temp.sort((a, b) =>
              a.firstName.toLowerCase() > b.firstName.toLowerCase() ? 1 : -1
            );
            that.tempuseritem = that.useritems;
            that.userCopy = that.useritems;
          }
        })
        .catch(function (err) {
          throw err;
        });
      return that.useritems.map((item, index) => ({ ...item, no: ++index }));
    },
  SelectAllUser() {
      if (this.userLISTD.length == 0) {
        this.userLISTD = this.useritems.map(({ userID }) => userID);
      } else {
        this.userLISTD = [];
      }
    },
    
  clearSearchWord(val) {
      switch (val) {
        case "group":
          this.searchgroup = null;
          break;
        case "user":
          this.searchuser = null;
          break;
        default:
          console.log("clear");
      }
      this.searchInLists();
    },
    clearSearchWordDetail(val) {
      switch (val) {
        case "group":
          this.searchgroup = null;
          break;
        case "user":
          this.searchuserDetail = null;
          break;
        default:
          console.log("clear");
      }
      this.searchInLists();
    },
  searchInLists() {
      switch (this.searchtype) {
        case "group":
          if (!this.searchgroup) {
            this.deptitems = this.groupCopy;
          }

          this.deptitems =
            this.searchgroup != null
              ? this.groupCopy.filter((x) => {
                  return (
                    x.groupName
                      .toLowerCase()
                      .indexOf(this.searchgroup.toLowerCase()) > -1
                  );
                })
              : this.groupCopy;
          break;
        case "user":
          if (!this.searchuser) {
            this.useritems = this.userCopy;
          }

          this.useritems =
            this.searchuser != null
              ? this.userCopy.filter((x) => {
                  return (
                    x.fullName
                      .toLowerCase()
                      .indexOf(this.searchuser.toLowerCase()) > -1
                  );
                })
              : this.userCopy;
          break;
        default:
          console.log("Searched...");
      }
    },
    searchInListsDetail() {
      switch (this.searchtype) {
        case "group":
          if (!this.searchgroup) {
            this.deptitems = this.groupCopy;
          }

          this.deptitems =
            this.searchgroup != null
              ? this.groupCopy.filter((x) => {
                  return (
                    x.groupName
                      .toLowerCase()
                      .indexOf(this.searchgroup.toLowerCase()) > -1
                  );
                })
              : this.groupCopy;
          break;
        case "user":
          if (!this.searchuserDetail) {
            this.useritems = this.userCopy;
          }

          this.useritems =
            this.searchuserDetail != null
              ? this.userCopy.filter((x) => {
                  return (
                    x.fullName
                      .toLowerCase()
                      .indexOf(this.searchuserDetail.toLowerCase()) > -1
                  );
                })
              : this.userCopy;
          break;
        default:
          console.log("Searched...");
      }
    },
  getSelectAll() {
      if (this.dept.length == 0) {
        this.dept = this.deptitems.map(({ groupID }) => groupID);
      } else {
        this.dept = [];
      }
    },
   
    
    async GetActivityReport() {
      let self = this;
      if(self.selectedOption){
        console.log("Selected Status:",self.selectedOption);
      }
      self.loading = true;
    
      let request = {
        companyID: localStorage.getItem("companyID"),
        userID: self.userLISTD ? self.userLISTD : [],
        departmentID: self.dept ? self.dept : [],
  
      };
     
      await axios
        .post(`${self.web_url}Reports/GetUserCourseStatusReport`, request)
        .then(function (response) {
          console.log("response.data.data",response.data.data);
          if (response.data.status == 0) {
            
            self.userCourceReport = response.data.data.map((v) => ({
              ...v,
            }));
            self.clear = false;
          }
        })
        .catch(function (err) {
          throw err;
        });
      self.loading = false;
    },
    
  SelectGroup(event) {
      this.useritems = [];
      this.userLISTD = [];
      if (event.length > 0) {
        let temp = this.deptitems.find((x) => x.groupID == event);
        if (temp != undefined) {
          for (let i of temp.groupEmployee) {
            this.useritems.push({
              fullName: i.employeeName,
              userID: i.employeeID,
            });
          }
        }
      } else {
        this.useritems = this.tempuseritem;
        this.GetUserItems();
        this.GetActivityReport();
      }
    },
    SelectUser(event) {
      if (event.length == 0) {
        if (
          this.userLISTD.length == 0 &&
          this.startdate == "" &&
          this.enddate == ""
        ) {
          this.useritems = [];
        }
      }
    },
    
  async getDepartment() {
      let self = this;
      const res = await self.$axios.get(
        `${self.web_url}Group/GetGroupALL?id=` +
          localStorage.getItem("companyID")
      );
     // console.log("companyId",companyID)
      //console.log("res.data.data",res.data.data);
      if (res.data.status == 0) {
        let temp = res.data.data;
        self.deptitems = temp.sort((a, b) =>
          a.groupName.toLowerCase() > b.groupName.toLowerCase() ? 1 : -1
        );
        self.groupCopy = self.deptitems;
      }
    },
  Detailss(item, subItem1){
        console.log("item",item);
        console.log("subItem",subItem1);
  },
  allowedDates: (val) => val >= new Date().toISOString().substr(0, 10),
    allowedEndDates(val) {
      return val >= this.startdate;
    },
   
    allowedDatesEnd(val) {
      return val >= this.tmpendDate;
    },
    
    calcRes(e) {
      this.tmpendDate = e;
      this.exportSdate = e;
    },
    startDateDis(selected) {
      this.calcRes(selected);
      this.frommenu = false;
      this.allowedDatesEnd.enddate = selected;
      if (this.enddate < this.allowedDatesEnd.enddate) {
        this.enddate = this.allowedDatesEnd.enddate;
      }
    },
    allowedviewDates: (val) => val >= new Date().toISOString().substr(0, 10),
    allowedviewEndDates(val) {
      return val >= this.viewstartdate;
    },
    StartDate() {
      this.frommenu = false;
      if (this.enddate == "") {
        this.enddate = new Date(
          Date.now() - new Date().getTimezoneOffset() * 60000
        )
          .toISOString()
          .substr(0, 10);
      }
      if (this.startdate > this.enddate) {
        this.enddate = this.startdate;
      }
    },
    EndDate() {
      if (this.startdate == "") {
        this.startdate = this.enddate;
      }
    },
    StartViewDate() {
      if (this.startdate > this.enddate) {
        this.enddate = this.startdate;
      }
    },
  
    exportDetailExcel() {
  const headers = ["#", "Course Name", "Start-End Date", "Type", "Content", "First View", "Last View", "Status"];
  const keys = ["index", "courseName", "startDateTime", "type", "items", "firstView", "lastView", "status"];

  const labels = [
    "Full Name:", "Group:", "Total Courses:", "Completion Percentage:", 
    "Complete:", "Not Started:", "In Progress:", "Delay:"
  ];

  const values = [
    this.itemSelectedDetail.fullName,
    this.itemSelectedDetail.groupName,
    this.itemSelectedDetail.totalCourses,
    this.itemSelectedDetail.completionPercentage,
    this.itemSelectedDetail.complete,
    this.itemSelectedDetail.notStarted,
    this.itemSelectedDetail.inProgress,
    this.itemSelectedDetail.delay,
  ];

  let workbook = new Exceljs.Workbook();
  let worksheet = workbook.addWorksheet("User Course Status Report", {
    views: [{ showGridLines: true }]
  });

  // ✅ **Set Label Column Width Before Adding Rows**
  worksheet.getColumn(1).width = 40; // Labels column
  worksheet.getColumn(2).width = 30; // Values column

  // 🟢 **SECTION 1: USER DETAILS**
  for (let i = 0; i < labels.length; i++) {
    const row = worksheet.addRow([labels[i], values[i]]);
    const labelCell = row.getCell(1);

    // Style for labels
    labelCell.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FF9900" }
    };
    labelCell.font = { bold: true };

    // Apply alignment & wrap text
    row.getCell(1).alignment = { horizontal: "left", vertical: "middle", wrapText: true };
    row.getCell(2).alignment = { horizontal: "left", vertical: "middle", wrapText: true };
  }

  // ✅ **Do NOT reset first two columns' width**
  worksheet.columns.forEach((column, i) => {
    if (i >= 2) {
      column.width = headers[i - 2] ? headers[i - 2].length + 10 : 20;
    }
  });

  // ✅ Add an empty row to separate sections
  worksheet.addRow([]);

  // 🟢 **SECTION 2: COURSE DETAILS**
  const data = [];
  data.push(headers);

  const filterItems = this.DetailsL.map((x) => ({ ...x }));
  for (const item of filterItems) {
    let rowOne = [];
    for (const key of keys) {
      rowOne.push(item[key] || " ");
    }
    rowOne[headers.indexOf("index")] = item.index;
    rowOne[headers.indexOf("courseName")] = item.courseName;
    rowOne[headers.indexOf("startDateTime")] = item.startDateTime;
    rowOne[headers.indexOf("content")] = item.items;
    rowOne[headers.indexOf("status")] = item.status;

    data.push(rowOne);

    // Add sub-items (contents) as rows
    data.push(
      ...item.contents.map((x) => [
        " ", " ", " ", 
        x.type || " ", 
        x.content || " ", 
        x.firstView ? this.formatDate(x.firstView) : " ", 
        x.lastView ? this.formatDate(x.lastView) : " ", 
        x.status || " "
      ])
    );
  }

  // ✅ **Set header styles**
  const headerRow = worksheet.addRow(headers);
  headerRow.eachCell((cell) => {
    cell.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "ff9900" }
    };
    cell.font = { bold: true, color: { argb: "000000" } };
    cell.alignment = { vertical: "middle", horizontal: "center" };
  });

  // ✅ **Add the course data rows**
  data.slice(1).forEach((row) => {
    worksheet.addRow(row);
  });

  // ✅ **Apply border and formatting to all cells**
  worksheet.eachRow((row) => {
    row.eachCell((cell) => {
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
      cell.alignment = { vertical: "middle", horizontal: "center" };
    });
  });

  // ✅ **Adjust column widths dynamically for Section 2**
  worksheet.columns.forEach((column, i) => {
    if (i === 0) {
    column.width = 25; // Decrease index column width
   } else if (i >= 2) {
     column.width = headers[i - 2] ? headers[i - 2].length + 20 : 25;
   }
  });

  // ✅ **Save the file**
  const fileName = "UserCourseStatusReportDetail.xlsx";
  workbook.xlsx.writeBuffer().then((val) => {
    let blob = new Blob([val], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });
    saveAs(blob, fileName);
  });
}
,

  
  exportExcel() {
  const fileName = "UserCourseStatusReport.xlsx";
  const workbook = new Exceljs.Workbook();
  const worksheet = workbook.addWorksheet("Report");

  // Define column headers
  worksheet.columns = [
    { key: "index", width: 10 },
    { key: "fullName", width: 20 },
    { key: "groupName", width: 20 },
    { key: "totalCourses", width: 15 },
    { key: "inProgress", width: 15 },
    { key: "notStarted", width: 15 },
    { key: "delay", width: 15 },
    { key: "completionPercentage", width: 35 }
  ];

  // Add header row
  const headerRow = worksheet.addRow([
    "#", "Full Name", "Group", "Total Course", "Inprogress",
    "Not Started", "Delay", "Completion Percentage"
  ]);

  // Apply formatting to the header row
  headerRow.eachCell((cell) => {
    cell.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FFFF00" } // Yellow background
    };
    cell.font = { name: "Calibri", size: 11, bold: true };
    cell.alignment = { horizontal: "center", vertical: "middle" };
  });

  // Process each item and add rows
  this.userCourceReport.forEach((item, index) => {
    const row = worksheet.addRow([
      index + 1, // Generate serial number
      item.fullName || "",
      item.groupName || "",
      item.totalCourses ?? 0,
      item.inProgress ?? 0,
      item.notStarted ?? 0 ,
      item.delay ?? 0,
      item.completionPercentage ? `${item.completionPercentage}%` : "0%"
    ]);

    // Ensure all cells in the row are center-aligned
    row.eachCell((cell) => {
      cell.alignment = { horizontal: "center", vertical: "middle" };
    });
  });

  // Save and download the file
  workbook.xlsx.writeBuffer().then((buffer) => {
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    });
    saveAs(blob, fileName);
  });
},



async Details(item) {
    this.detaildialog = true;
    this.resetTab();
    
    console.log("resetTab",this.resetTab);
    this.loading = true;
    this.itemSelectedDetail = item;
    console.log("itemSelectedDetail",this.itemSelectedDetail.userID);
    let request = {
        companyID: localStorage.getItem("companyID"),
        userID: item.userID
    };

    try {
        const response = await axios.post(`${this.web_url}Reports/GetUserCourseDetailsReport`, request);

        if (response.data.status === 0) {
            // Use JSON.parse to log the actual data
            const rawData = JSON.parse(JSON.stringify(response.data.data)); 
           // console.log("API data received:", rawData);
            this.DetailsList = rawData;
          //  console.log("this.Detailist",this.DetailsList);
        }
    } catch (err) {
        console.error("Error fetching details:", err);
    } finally {
        this.loading = false;
        this.loadData();
    }
},
async searchDetails(item){
  
  let self = this;
      self.loading = true;
      let status = "";
  switch (this.activeTab) {
    case 0:
      status = "completed";
      break;
    case 1:
      status = "notStarted";
      break;
    case 2:
      status = "inProgress";
      break;
    case 3:
      status = "delayed";
      break;
    default:
      status = "unknown"; // ✅ Fallback for safety
  }
  console.log("Assigned Status:", status);
  if (!self.startdate || !self.enddate) {
    alert("Please select both Start Date and End Date before searching.");
    return; 
  }
 
     
    const  userId= item.userID;
      let requestData = {
        companyID: localStorage.getItem("companyID"),
        startDateFilter: self.clear === false && self.startdate
        ?new Date(self.startdate).toISOString() :"",
        endDateFilter: self.clear === false && self.enddate 
        ? new Date(self.enddate).toISOString() 
        : "",
        tabFilter: status,
       userID : userId,
       selectedCourseId: [],
      };
    
      console.log("API Request Data:", requestData);

 try {
     const response = await axios.post(`${this.web_url}Reports/GetFilteredUserCourseDetails`, requestData);
    
   // console.log("response.data.data", response.data.data);

    if (response.data.status === 0) {
        // this.DetailsL = response.data.data.map((v) => ({ ...v }));
        this.DetailsL = response.data.data.courses.map((item,index) => ({
          ...item,
          index: index + 1
        }));
        this.clear = false;
    }
    //console.log("this.Detaillsl",this.DetailsL);
 } catch (err) {
     console.error("API Error:", err);
}
//console.log('details data => ', this.DetailsL);
this.loading = false;

},
async searchMain() {  // Add 'async' here
    console.log("Selected Status:", this.selectedOption);

    
    const requestData = {
       status:this.selectedOption,
        companyID: localStorage.getItem("companyID"),
        userID: this.userLISTD ? this.userLISTD : [],
        departmentID: this.dept ? this.dept : [],
    
    };

    console.log("API Request Data:", requestData);

    try {
        const response = await axios.post(`${this.web_url}Reports/GetUserCourseStatusReport`, requestData);
        
        //console.log("response.data.data", response.data.data);

        if (response.data.status === 0) {
            this.userCourceReport = response.data.data.map((v) => ({ ...v }));
            this.clear = false;
        }
    } catch (err) {
        console.error("API Error:", err);
    }

    this.loading = false;
}
,
},
mounted()
{
  this.loadData();
 
  this.GetActivityReport();
  this.getDepartment();
  this.GetUserItems();
    let year = new Date().getFullYear();
    let month =
      new Date().getMonth() + 1 <= 9
        ? "0" + parseInt(new Date().getMonth() + 1)
        : new Date().getMonth() + 1;
    let day =
      new Date().getDate() <= 9
        ? "0" + parseInt(new Date().getDate())
        : new Date().getDate();
    this.startDateDis(`${year}-${month}-${day}`);
    // let years = new Date().getFullYear();
    // let months =
    //   new Date().getMonth() + 1 <= 9
    //     ? "0" + parseInt(new Date().getMonth() + 1)
    //     : new Date().getMonth() + 1;
    // let days =
    //   new Date().getDate() <= 9
    //     ? "0" + parseInt(new Date().getDate())
    //     : new Date().getDate();
   // this.ExportstartDateDis(`${years}-${months}-${days}`);
}
};
</script>
<style scoped>
@media (min-width: 960px) {
  .container {
    max-width: unset !important;
  }
}
.v-card {
  border-radius: 16px !important;
}
::v-deep
  .v-data-table
  > .v-data-table__wrapper
  tbody
  tr.v-data-table__expanded__content {
  background: #f5f9e9;
}
::v-deep .th,
td.content-filename {
  width: 180px;
  overflow: hidden;
  word-break: break-all !important;
  background-color: unset;
}
::v-deep .th,
td.content-content {
  width: 200px;
  overflow: hidden;
  word-break: break-all !important;
  background-color: unset;
}
::v-deep .th,
td.content-firstView {
  width: 220px;
  overflow: hidden;
  word-break: break-all !important;
  background-color: unset;
}
::v-deep .th,
td.content-lastView {
  width: 210px;
  overflow: hidden;
  word-break: break-all !important;
  background-color: unset;
}
::v-deep .th,
td.content-status {
  width: 180px;
  overflow: hidden;
  word-break: break-all !important;
  background-color: unset;
}
.report-table tr:nth-child(even) {
  background: unset;
}
.report-table thead th:first-child {
  border-radius: 8px 0 0 8px !important;
}
.report-table thead th:last-child {
  border-radius: 0px 8px 8px 0px !important;
}
.searchinlistdetail.v-text-field {
  margin-left: 5px;
  padding-top: 0px;
  margin-top: 0px;
}
::v-deep .searchinlistdetail.v-text-field > .v-input__control > .v-input__slot {
  margin-left: 0px;
}
::v-deep .v-data-table__expand-icon {
  outline: none !important;
  border-radius: 0px !important;
} 
::v-deep .v-data-table__expand-icon.v-icon.v-icon.v-icon--link {
  cursor: pointer;
  outline: 1px solid !important;
  border-radius: 50% !important;
  font-size: 20px;
  color: #424242;
}
::v-deep .searchinlist.v-text-field > .v-input__control > .v-input__slot {
  margin-left: 0px;
}
.searchinlist.v-text-field {
  margin-left: 5px;
  padding-top: 0px;
  margin-top: 0px;
}
::v-deep td.sub-content {
  width: 230px !important;
  overflow: hidden;
  word-break: break-all !important;
  background-color: unset;
  height: 45px;
  padding-right: 16px;
} 
 ::v-deep td.detail-column {
  overflow: hidden;
  word-break: break-all !important;
  background-color: unset;
  height: 45px;
  padding-left: 10px;
} 
 ::v-deep .detail-icon.v-icon.v-icon.v-icon--link {
  cursor: pointer;
  outline: none !important;
} 
 .report-table tr:nth-child(even) {
  background: unset;
} 
 .report-table thead th:first-child {
  border-radius: 8px 0 0 8px !important;
} 
 .report-table thead th:last-child {
  border-radius: 0px 8px 8px 0px !important;
} 
 ::v-deep .pagination .primary {
  background-color: #a6cc39 !important;
  color: #ffffff !important;
  opacity: 1;
} ::v-deep .primary {
  background-color: #a6cc39 !important;
}
::v-deep .accent {
  background-color: #a6cc39 !important;
}
::v-deep .accent--text {
  color: #a6cc39 !important;
}
::v-deep .primary--text {
  color: #a6cc39 !important;
}
 .v-pagination__navigation {
  border-radius: 6px !important;
  box-shadow: none !important;
  border: 1px solid #c0c0c0;
}
.v-pagination__item {
  background: transparent !important;
  box-shadow: none !important;
}
::v-deep
  .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr:last-child
  > th {
  font-size: 17px !important;
  color: #47484b !important;
  background-color: #e0e0e0 !important;
  opacity: 1;
}
::v-deep td.course-detail {
  width: 150px !important;
  overflow: hidden;
  word-break: break-all !important;
  background-color: unset;
  height: 45px;
}

</style>